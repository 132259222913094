$off-white: #fbfffe;
$light-grey-shadow:#ccc;
$extra-light-grey:#eee;
$light-grey: #939aa5;
$med-grey:#a09e9e;
$dark-grey: #6f6f6f;
$wallflower:#3778b9;
$purple-blue:#8398d8;
$baby-blue:#aed6ff;
$light-blue:#f4f6ff;
$light-aqua:#f4fffd;
$aqua: #2c9f89;
$turquoise: #7ec4b7;
$sage:#9fd0a8;
$light-green:#f4fff6;
$dark-green:#2cab4f;
$light-purple:#f9f4ff;
$dark-purple:#512e91;
