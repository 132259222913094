@import '../utilities/colors';

.timeline {
    width: 80%;
    font-family: "Poppins", sans-serif;

    .work-exp-header {
        position: relative;

        &::after {
            content: "";
            position: absolute;
            background: $off-white;
            border: 3px solid $light-grey;
            border-radius: 50%;
            width: 16px;
            height: 16px;
            top: 45%;
            left: -11px;
            z-index: 100;
        }

        img {
            display: block;
            width: 50px;
            margin-right: 2%;
        }

        h1 {
            font-size: 20px;
            margin-bottom: 0;
            align-self: center;
        }
    }

    .role {
        &:nth-child(odd) {
            border-bottom: 2px solid $extra-light-grey;
        }

        .work-info {
            margin: 20px 0;
    
            p {
                margin: 0;
                padding: 0;
                font-weight: 600;
            }
    
            .title {
                color: $dark-grey;
            }
        }
    
        .work-resp {
            div {
                margin-bottom: 20px;
            }
        }
    }
}


.accordion {
    .accordion-item {
        border: none;
    }

    .accordion-button {
        &::after {
            display: none;
        }

        &:not(.collapsed), &:focus {
            box-shadow: none;
            color: black;
            background-color: $off-white;
        }
    }

    .amex {
        .accordion-button {
            &:not(.collapsed), &:focus {
                color: $purple-blue;
                background-color: $light-blue;
            }        
        }
    }

    .pd {
        img {
            padding: 5px;
        }

        .accordion-button {
            &:not(.collapsed), &:focus {
                color: $dark-green;
                background-color: $light-green;
            }        
        }
    }
    
    .nyu {
        img {
            padding: 5px;
        }

        .accordion-button {
            &:not(.collapsed), &:focus {
                color: $dark-purple;
                background-color: $light-purple;
            }        
        }
    }

    .gwc {
        img {
            padding: 2px;
        }

        .accordion-button {
            &:not(.collapsed), &:focus {
                color: $aqua;
                background-color: $light-aqua;
            }        
        }
    }
}


@media (max-width: 768px) {
    .timeline {
        width: 100%;
    }
}
