@import '../utilities/colors';

.project-details {
    margin-bottom: 200px;

    &-back {
        border: none;
        background-color: transparent;
        font-size: 1.2em;

        &:hover {
            color: $aqua;
        }
    }

    &-header {
        font-family: 'Poppins', sans-serif;
        margin-top: 5%;

        h4 {
            color: $dark-grey;
            font-size: 1em;
            font-weight: 600;
        }

        .skills {
            margin-bottom: 20px;

            .skill-container {
                display: flex;
                flex-wrap: wrap;

                .tool {
                    background: rgba(0, 184, 148, 0.05);
                    color: black;
                    border: 1px solid #4e5755;
                    border-radius: 5px;
                    margin-right: 10px;
                    padding: 3px 10px;
                    margin-bottom: 5px;
                }
            }
        }

        .links {
            margin-top: 20px;

            img {
                margin-right: 5px;

                &:hover {
                    cursor: pointer;
                }
            }
        }
    }

    &-info {
        margin-top: 2em;
    }
    &-poster-parent {
        padding: 0 10%;
    }

    &-poster {
        width: 100%;
        border-radius: 20px;
    }

    &-body {
        margin-top: 5%;
        .overview, .development {
            font-family: 'Inter', sans-serif;
            margin: 50px 0;

            h3 {
                letter-spacing: 3px;
                margin-bottom: 15px;
            }
            p {
                text-align: justify;
            }
        }
    }

}

@media (max-width: 768px) {
    .links {
        margin-bottom: 40px;
    }
}