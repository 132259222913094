@import '../utilities/colors';

footer {
    position: relative;
    height: 100px;
    color: $dark-grey;

    .info {
        background: $off-white;
        position: absolute;
        display: flex;
        top: 30px;
        width: 100%;
        z-index: 1000;
        justify-content: space-between;

        p {
            padding-left: 5%;
        }

        .socials {
            padding-right: 5%;
            
            img {
                width: 25px;
                height: 25px;
                margin: 0 8px;
            }
        }
    }

    .waves {
        background: $off-white;
        position: relative;
        width: 100%;
        height: 100%;

        .wave {
            position: absolute;
            left: 0;
            top: -70px;
            width: 100%;
            height: 100px;
            background: url(../media/icons/wave.png);
            background-size: 1000px 100px;
            filter: drop-shadow(0 0 3px rgb(171, 170, 171));
        }
    
        .wave1 {
            z-index: 1000;
            opacity: 1;
            animation: animate 30s linear infinite;
            animation-delay: 0s;
        }
    
        .wave2 {
            top: -60px;
            z-index: 999;
            opacity: 0.5;
            animation: animate2 15s linear infinite;
            animation-delay: -5s;
        }
    
        .wave3 {
            top: -55px;
            z-index: 998;
            opacity: 0.2;
            animation: animate 30s linear infinite;
            animation-delay: -2s;
        }
    
        .wave4 {
            top: -50px;
            z-index: 997;
            opacity: 0.7;
            animation: animate2 5s linear infinite;
            animation-delay: -5s;
        }
    }

}

@keyframes animate {
    0% {
        background-position-x: 0;
    }

    100% {
        background-position-x: 1000px;
    }
}

@keyframes animate2 {
    0% {
        background-position-x: 0;
    }

    100% {
        background-position-x: -1000px;
    }
}
