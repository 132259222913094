@import "~bootstrap/scss/bootstrap";
@import 'utilities/colors';
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;1,100;1,200;1,300;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Hind:wght@300;400;500&family=Inter:wght@100;300;400;500&display=swap');

.content-start {
    padding-top: 85px;
}

.section-heading {
    font-size: 2em;
    font-weight: 600;
    text-transform: uppercase;
    font-family: 'Hind', sans-serif;
    letter-spacing: 2px;
    color: black;
}

.grey-rounded-border {
    border-radius: 0.8em;
    border-left: 0.4em solid $med-grey;
    border-top: 0.4em solid $extra-light-grey;
    border-right: 0.4em solid $extra-light-grey;
    border-bottom: 0.4em solid $extra-light-grey;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
}

img {
    pointer-events: none;
}
