@import '../utilities/colors';

.project {
    border-radius: 1em;
    overflow: hidden;
    transition: all 0.5s ease-in-out;

    &:hover {
        cursor: pointer;
        box-shadow: 0 2px 40px 0 $light-grey-shadow;
        transform: translateY(-0.8em);
    }

    &-url, &-url:hover {
        text-decoration: none;
        color: black;
    }

    &-name {
        margin: 10px 0;
    }

    &-tech {
        margin-bottom: 20px;
        .tool {
            background: rgba(0, 184, 148, 0.05);
            color: black;
            border: 1px solid #4e5755;
            border-radius: 5px;
            margin-right: 10px;
            padding: 3px 10px;
        }
    }

    &-description {
        padding-bottom: 10px;
    }
}
