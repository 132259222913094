.photo-gallery {
    column-count: 3;
    column-width: 33%;
    padding: 0 12px;

    .pics {
        display: inline-block;
        transform: all 350ms ease;
        cursor: pointer;
        margin-bottom: 12px;

        img {
            width: 100%;
            &:hover {
                filter: opacity(0.8);
            }
        }
    }
}

@media (max-width:990px) {
    .photo-gallery {
        column-count: 2;
        column-width: 50%;
    }
}

@media (max-width:480px) {
    .photo-gallery {
        column-count: 1;
        column-width: 100%;
    }
}
