@import '../utilities/colors';

header {
    background: $off-white;
    position: relative;
    font-family: 'Poppins', sans-serif;
}

.header-name {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 60px;
    z-index: 1;

    .lead {
        font-size: 30px;
        color: $aqua;
        font-weight: 400;
    }

    .name {
        color: $dark-grey;
    }

    .title {
        font-size: 20px;
        text-align: center;
        color: $light-grey;
    }
}

@media (max-width: 576px) {
    .header-name {
        width: 100%;
        text-align: center;
    }

    .title {
        margin-top: 10px;
    }
}
